import { PHONE } from "../../../data/common";
import { maskPhone } from "../../../utils";
import css from './Header.module.scss'
import Link from 'next/link'
import {useState, useEffect} from 'react'
import dynamic from 'next/dynamic'
import {useSelector} from "react-redux";
const DynamicHeaderMenu = dynamic(() => import('./HeaderMenu'), {ssr: false})

const Header = ({visibility_map}) => {
    const { favorites } = useSelector(state => state.catalog)
    const [isVisibleMenu, setIsVisibleMenu] = useState(false)

    useEffect(()=> {
        const body = document.querySelector('body');

        const handleClose = () => {
            isVisibleMenu && setIsVisibleMenu(false)
        }

        body.addEventListener('click', handleClose);
        if(isVisibleMenu){
            body.classList.add('menu-open');
        }
        return () => {
            body.classList.remove('menu-open');
            body.removeEventListener('click', handleClose);
        }

    }, [isVisibleMenu])

    return (
        <header
            className={isVisibleMenu ? `${css.header} ${css.open}` : `${css.header}`}
        >
            <div className={css.top}>
                <div className={css.item}>
                    <div
                        className={isVisibleMenu ? `${css.button} ${css.active}` : `${css.button}`}
                        onClick={() => setIsVisibleMenu(!isVisibleMenu)}
                    >
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                    <Link href="/">
                        <a className={css.logo}>Logo</a>
                    </Link>
                    {visibility_map.length > 0 && visibility_map.includes('/vybor-kvartiri') && <Link href={"/vybor-kvartiri"}>
                        <a className={isVisibleMenu ? `${css.link} ${css.bordered} ${css.hide}` : `${css.link} ${css.bordered}`}>Выбрать квартиру</a>
                    </Link>}
                    {visibility_map.length > 0 && visibility_map.includes('/ipoteka') &&<Link href={"/ipoteka"}>
                        <a className={isVisibleMenu ? `${css.link} ${css.hide}` : `${css.link}`}>Ипотека</a>
                    </Link>}
                    {visibility_map.length > 0 && visibility_map.includes('/promotions') &&<Link href={"/promotions"}>
                        <a className={isVisibleMenu ? `${css.link} ${css.hide}` : `${css.link}`}>Акции</a>
                    </Link>}
                    {/*{visibility_map.length > 0 && visibility_map.includes('/hod-stroitelstva') &&<Link href={"/hod-stroitelstva"}>
                        <a className={isVisibleMenu ? `${css.link} ${css.hide}` : `${css.link}`}>Ход строительства</a>
                    </Link>}*/}
                    {visibility_map.length > 0 && visibility_map.includes('/contacts') && <Link href={"/contacts"}>
                        <a className={isVisibleMenu ? `${css.link} ${css.hide}` : `${css.link}`}>Контакты</a>
                    </Link>}
                </div>
                <div className={`${css.item} ${css.right}`}>
                    <a href={`tel:${PHONE}`} className={css.phone}>{maskPhone(PHONE)}</a>
                    <a href={'https://www.granelle.ru/'} target="_blank" className={css.developer}>
                        <img src="/files/images/svg/logo-granelle_2.svg" alt="" loading={"lazy"} width={'118'} height={'21'}/>
                        <span>ГК Гранель</span>
                    </a>
                    <div className={css.line}></div>
                    <Link href={"/favorites"}>
                        <div className={css.favorites}>
                            <img src="/files/images/svg/favorite.svg" alt="" loading={"lazy"} width={'35'} height={'21'}/>
                            <span className={css.count}>{favorites.length ? favorites.length : null}</span>
                        </div>
                    </Link>

                </div>
            </div>
            {isVisibleMenu && <DynamicHeaderMenu visibility_map={visibility_map} />}
        </header>
    )
}

export default Header
